export const pcRoutes = [
	{
	  path: '/index',
	  component: () => import('@/views/pc/index'),
	  name: 'Index',
	  meta: { title: '首页', activeMenu: '/index' }
	},
	// {
	//   path: '/introduce',
	//   component: () => import('@/views/pc/introduce/index.vue'),
	//   redirect: '/introduce/news',
	//   name: 'Introduce',
	//   meta: { title: '走进奇葩', activeMenu: '/introduce', noCache: true },
	// 		children: [
	// 		  {
	// 			path: 'aboutus',
	// 			component: () => import('@/views/pc/introduce/aboutus.vue'),
	// 			name: 'Aboutus',
	// 			meta: { title: '公司简介', activeMenu: '/introduce' },
	// 		  },
	// 		  {
	// 			path: 'news',
	// 			component: () => import('@/views/pc/introduce/news.vue'),
	// 			name: 'News',
	// 			meta: { title: '奇葩大事件', activeMenu: '/introduce' },
	// 		  },
	// 		  {
	// 			path: 'news-detail/:Id(\\d+)',
	// 			component: () => import('@/views/pc/introduce/newsdetail.vue'),
	// 			name: 'Newsdetail',
	// 			meta: { title: '奇葩大事件', activeMenu: '/introduce' },
	// 		  },
	// 		]
	// },
	// {
	//   path: '/product',
	//   component: () => import('@/views/pc/product'),
	//   redirect: '/product/recommend',
	//   name: 'Product',
	//   meta: { title: '联研产品', activeMenu: '/product', noCache: true },
	// 		children: [
	// 		  {
	// 			path: 'recommend',
	// 			component: () => import('@/views/pc/product/recommend.vue'),
	// 			name: 'Recommend',
	// 			meta: { title: '重点推荐游戏', activeMenu: '/product' },
	// 		  },
	// 		  {
	// 			path: 'list',
	// 			component: () => import('@/views/pc/product/list.vue'),
	// 			name: 'List',
	// 			meta: { title: '联研产品', activeMenu: '/product' },
	// 		  },
	// 		]
	// },
	{
	  path: '/culture',
	  component: () => import('@/views/pc/culture/index.vue'),
	  redirect: '/culture/concept',
	  name: 'Culture',
	  meta: { title: '公司文化', activeMenu: '/culture', noCache: true },
			children: [
			  {
				path: 'concept',
				component: () => import('@/views/pc/culture/concept.vue'),
				name: 'Concept',
				meta: { title: '文化理念', activeMenu: '/culture' },
			  },
			]
	},
	{
	  path: '/joinus',
	  component: () => import('@/views/pc/joinus/index.vue'),
	  redirect: '/joinus/social',
	  name: 'Joinus',
	  meta: { title: '加入我们', activeMenu: '/joinus', noCache: true },
			children: [
			  {
			    path: 'social',
			    component: () => import('@/views/pc/joinus/social.vue'),
			    name: 'Social',
			    meta: { title: '社会招聘', activeMenu: '/joinus' },
			  },
			  {
			    path: 'school',
			    component: () => import('@/views/pc/joinus/school.vue'),
			    name: 'School',
			    meta: { title: '校园招聘', activeMenu: '/joinus' },
			  },
			]
	},
	{
	  path: '/contact',
	  component: () => import('@/views/pc/contact'),
	  name: 'Contact',
	  meta: { title: '联系我们', activeMenu: '/contact' }
	},
];