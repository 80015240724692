<template>
	<div class="recruit m">
		<div class="tabList">
			<ul>
				<template v-for="(item,index) in tabList" :key="index">
					<li :class="{'active':item.id == activeItem}" @click="activeItem = item.id">{{ item.moduleName }}</li>
				</template>
			</ul>
		</div>
		<div class="content">
			<template v-if="recruitList.length > 0">
				<div class="recruitItem" v-for="(item,index) in recruitList" :key="index">
					<h4 class="title">{{ item.recruitName }}</h4>
					<div class="content" v-html="item.recruitContent"></div>
				</div>
			</template>
			<el-empty v-else description="暂无数据" />
		</div>
	</div>
</template>

<script setup>
	import { getRecruitList,getPartList } from '@/api/index'
	import { ref, reactive, onMounted, watch, getCurrentInstance } from 'vue'
	
	const { proxy } = getCurrentInstance();
	let tabList = ref([])
	let recruitList = ref([])
	let activeItem = ref(0)
	let queryParams = reactive({
		pageNum: 1,
		pageSize: 6
	})
	onMounted(()=>{
		getList()
		// getContent(activeItem.value)
	})
	watch(activeItem, (newVal, oldValue) => {
		getContent(newVal)
	})
	
	const getList = () => {//社会招聘6
		getPartList(proxy.partten.partRecruitSocial).then((res)=>{
			tabList.value = res.data;
			if(res.data.length > 0){
				activeItem.value = res.data[0].id
			}
		})
	}
	const getContent = (val) => {
		queryParams = Object.assign(queryParams, { ifPage: 1, moduleId:val });
		getRecruitList(queryParams).then((res)=>{
			recruitList.value = res.data;
		})
	}
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>

</style>
