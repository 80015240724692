<template>
  <div class="bannerTop"><img src="@/assets/images/contact-top.jpg"></div>
  <div class="conBox">
      <div class="main">
          <div class="mapBox" id="mapBox"></div>
		  <div class="addresBox mt20">
		      <ul>
		          <li>
		              <p class="name">湖南奇葩乐游网络科技有限公司</p>
		                <div class="lineBox">
		                    <p>地址：{{ address }}</p>
		                    <p>电话：{{ telephone }}</p>
		                </div>
		          </li>
		      </ul>
		  </div>
          <div class="connectBox mt20">
              <ul>
                  <li>
                      <p class="title">商务合作</p>
                      <p>联系人：{{ businessName }}</p>
                      <p>合作邮箱：{{ businessEmail }}</p>
                  </li>
                  <li>
                      <p class="title">人事招聘</p>
                      <p>联系人：{{ recruitName }}</p>
                      <p>简历投递邮箱：{{ recruitEmail }}</p>
                  </li>
              </ul>
          </div>
      </div>
  </div>
</template>

<script setup>
import { MP } from '@/utils/TMap.js'
import { nextTick, onMounted, ref, getCurrentInstance } from 'vue'
import { getPartDetail } from '@/api/index'

const { proxy } = getCurrentInstance(); 

const businessEmail = ref('liuwei@7pa.com');//合作邮箱
const businessName = ref('刘蔚');//合作联系人
const recruitEmail = ref('liusi@7pa.com');//招聘邮箱
const recruitName = ref('HR刘女士');//招聘联系人
const telephone = ref('0731-83526133，020-85588660');//公司电话
const address = ref('长沙市芙蓉中路442号新湖南大厦事业楼1201');//公司地址

onMounted(() => {//50其他配置模块
	getPartDetail({ ifPage: 0, moduleId: proxy.partten.moduleOtherConnecat }).then((res)=>{
		res.rows.map((item) => {
			if(item.title == '合作邮箱'){
				businessEmail.value = proxy.filterHTML(item.textDescription);
			}else if(item.title == '联系地址'){
				address.value = proxy.filterHTML(item.textDescription);
			}else if(item.title == '简历投递邮箱'){
				recruitEmail.value = proxy.filterHTML(item.textDescription);
			}else if(item.title == '招聘联系人'){
				recruitName.value = proxy.filterHTML(item.textDescription);
			}else if(item.title == '商务联系人'){
				businessName.value = proxy.filterHTML(item.textDescription);
			}else if(item.title == '联系电话'){
				telephone.value = proxy.filterHTML(item.textDescription);
			}
		})
	})
	nextTick(() => {
		initMap()
	})
})

const initMap = () => {
	MP().then((TMap) => {
		var maps = TMap;
		// 定义地图中心点坐标
		var center = new maps.LatLng(28.207306, 112.985214)
		// 定义map变量，调用 TMap.Map() 构造函数创建地图
		var map = new maps.Map(document.getElementById('mapBox'), {
			center: center, // 设置地图中心点坐标
			zoom: 17.2, // 设置地图缩放级别
			pitch: 43.5, // 设置俯仰角
			rotation: 45 // 设置地图旋转角度
		})
		//初始化marker
		var marker = new maps.MultiMarker({ //eslint-disable-line no-unused-vars
			map: map,
			geometries: [{ //点标注数据数组
				"position": center,
			}]
		});
		//初始化label
		var label = new maps.MultiLabel({ //eslint-disable-line no-unused-vars
			map: map,
			styles: {
				'label': new maps.LabelStyle({
					'color': '#3777FF', //颜色属性
					'size': 20, //文字大小属性
					'offset': { x: 0, y: 0 }, //文字偏移属性单位为像素
					'angle': 0, //文字旋转属性
					'alignment': 'center', //文字水平对齐属性
					'verticalAlignment': 'middle' //文字垂直对齐属性
				})
			},
			geometries: [{
				'id': 'label', //点图形数据的标志信息
				'styleId': 'label', //样式id
				'position': center, //标注点位置
				'content': '湖南奇葩乐游网络科技有限公司', //标注文本
				'properties': { //标注点的属性数据
					'title': 'label'
				}
			}]
		});
	
	})
}

</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
.bannerTop{
	position: relative;width: 100%;height: 230px;overflow: hidden;
	img{
		height: 100%;position: absolute;width: 2000px;
		top: 0;left: 50%;transform: translate(-50%,0);
	}
}
.conBox{
    background: #eee;
    .main{
        width: 1200px;margin: 0 auto;padding: 30px 0;box-sizing:border-box;
        .mapBox{width: 100%;background: #fff;height: 350px;box-shadow: 0px 0px 20px #ddd;}
        .connectBox{
            width: 100%;
            ul{
                width: 100%;display: flex;align-items: center;justify-content: space-between;
                li{
                    width: 49%;background: #fff;box-sizing:border-box;padding: 20px 30px;box-shadow: 0px 0px 20px #ddd;
                    p{
                        font-size: 14px;color: #666;line-height:24px;
                        &.title{
                            font-size: 18px;color: #333;margin-bottom: 8px;
                        }
                    }
                }
            }
        }
        .addresBox{
            width: 100%;background: #fff;box-sizing:border-box;padding: 0 30px;box-shadow: 0px 0px 20px #ddd;
            ul{
                li{
                    padding: 20px 0;border-bottom:1px dashed #eee;
                    p.name{font-size: 18px;color: #333;margin-bottom: 5px;}
                    .lineBox{
                        width: 100%;display: flex;align-items: center;
                        p{
                            font-size: 14px;color: #666;
                            &:nth-child(1){width: 60%;}
                        }
                    }
					&:last-child{border-bottom: none;}
                }
            }
            
        }
    }
}
</style>
