import request from "@/utils/request";

// 主页
export function getPartDetail(query) {
  return request({
    url: '/officialWebsite/open/getResources',
    headers: {
      isToken: true
    },
    method: 'get',
	params: query
  })
}
// 获取模块列表
export function getPartList(id) {
  return request({
    url: '/officialWebsite/open/getModuleByPartId/' + id,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}
// 获取招聘列表
export function getRecruitList(query) {
  return request({
    url: '/officialWebsite/open/getRecruit',
    headers: {
      isToken: true
    },
    method: 'get',
	params: query
  })
}

