import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import plugins from './plugins'
import ElementPlus from 'element-plus'

require('@/assets/css/quill.bubble.css')
require('swiper/swiper-bundle.css')
require("swiper/components/pagination/pagination.min.css")
require("swiper/components/navigation/navigation.min.css")

import 'element-plus/dist/index.css'
import "@/assets/css/common.less"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(ElementPlus)
app.use(router)
app.use(store)
app.use(plugins)
app.mount('#app')
