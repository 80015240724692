export const mRoutes = [
	{
	  path: '/index',
	  component: () => import('@/views/m/index'),
	  name: 'mIndex',
	  meta: { title: '首页', activeMenu: '/index' }
	},
	// {
	//   path: '/introduce',
	//   component: () => import('@/views/m/introduce/index.vue'),
	//   redirect: '/introduce/news',
	//   name: 'mIntroduce',
	//   meta: { title: '走进奇葩', activeMenu: '/introduce', noCache: true },
	// 		children: [
	// 		  {
	// 			path: 'aboutus',
	// 			component: () => import('@/views/m/introduce/aboutus.vue'),
	// 			name: 'mAboutus',
	// 			meta: { title: '公司简介', activeMenu: '/introduce' },
	// 		  },
	// 		  {
	// 			path: 'news',
	// 			component: () => import('@/views/m/introduce/news.vue'),
	// 			name: 'mNews',
	// 			meta: { title: '奇葩大事件', activeMenu: '/introduce' },
	// 		  },
	// 		  {
	// 			path: 'news-detail/:Id(\\d+)',
	// 			component: () => import('@/views/pc/introduce/newsdetail.vue'),
	// 			name: 'Newsdetail',
	// 			meta: { title: '奇葩大事件', activeMenu: '/introduce' },
	// 		  },
	// 		]
	// },
	// {
	//   path: '/product',
	//   component: () => import('@/views/m/product'),
	//   redirect: '/product/recommend',
	//   name: 'mProduct',
	//   meta: { title: '联研产品', activeMenu: '/product', noCache: true },
	// 		children: [
	// 		  {
	// 			path: 'recommend',
	// 			component: () => import('@/views/m/product/recommend.vue'),
	// 			name: 'mRecommend',
	// 			meta: { title: '重点推荐游戏', activeMenu: '/product' },
	// 		  },
	// 		  {
	// 			path: 'list',
	// 			component: () => import('@/views/m/product/list.vue'),
	// 			name: 'mList',
	// 			meta: { title: '联研产品', activeMenu: '/product' },
	// 		  },
	// 		]
	// },
	{
	  path: '/culture',
	  component: () => import('@/views/m/culture/index.vue'),
	  redirect: '/culture/concept',
	  name: 'mCulture',
	  meta: { title: '公司文化', activeMenu: '/culture', noCache: true },
			children: [
			  {
				path: 'concept',
				component: () => import('@/views/m/culture/concept.vue'),
				name: 'mConcept',
				meta: { title: '文化理念', activeMenu: '/culture' },
			  },
			]
	},
	{
	  path: '/joinus',
	  component: () => import('@/views/m/joinus/index.vue'),
	  redirect: '/joinus/social',
	  name: 'mJoinus',
	  meta: { title: '加入我们', activeMenu: '/joinus', noCache: true },
			children: [
			  {
			    path: 'social',
			    component: () => import('@/views/m/joinus/social.vue'),
			    name: 'mSocial',
			    meta: { title: '社会招聘', activeMenu: '/joinus' },
			  },
			  {
			    path: 'school',
			    component: () => import('@/views/m/joinus/school.vue'),
			    name: 'mSchool',
			    meta: { title: '校园招聘', activeMenu: '/joinus' },
			  },
			]
	},
	{
	  path: '/contact',
	  component: () => import('@/views/m/contact'),
	  name: 'mContact',
	  meta: { title: '联系我们', activeMenu: '/contact' }
	},
];