<template>
	<div class="recruit">
		<div class="tabList">
			<span class="labelTitle">类别：</span>
			<ul>
				<template v-for="(item,index) in tabList" :key="index">
					<li :class="{'active':item.id == activeItem}" @click="activeItem = item.id">{{ item.moduleName }}</li>
				</template>
			</ul>
		</div>
		<div class="content">
			<template v-if="recruitList.length > 0">
				<div class="recruitItem" v-for="(item,index) in recruitList" :key="index">
					<h4 class="title">{{ item.recruitName }}</h4>
					<div class="content" v-html="item.recruitContent"></div>
				</div>
			</template>
			<el-empty v-else description="暂无数据" />
		</div>
		<div class="questions">
			<swiper
			  :spaceBetween="30" 
			  :centeredSlides="true" 
			  :autoplay='{ "delay": 2500, "disableOnInteraction": false }' 
			  :pagination='{ "clickable": true }' 
			  :navigation="false" 
				:style="{
				  '--swiper-pagination-color': '#ff5a00'
				}"
			  >
			  <swiper-slide v-for="(item,index) in quesList" :key="index">
				<div class="quesItem">
					<h4>Q：{{ item.title }}</h4>
					<p>A：{{ filterHTML(item.textDescription) }}</p>
				</div>
			  </swiper-slide>
			</swiper>
		</div>
	</div>
</template>

<script setup>
	import { Swiper, SwiperSlide } from 'swiper/vue';
	import SwiperCore, { Autoplay,Pagination } from 'swiper/core';
	SwiperCore.use([Autoplay,Pagination]);
	
	import { getRecruitList,getPartList,getPartDetail } from '@/api/index'
	import { ref, reactive, onMounted, watch, getCurrentInstance } from 'vue'
	
	const { proxy } = getCurrentInstance();
	let tabList = ref([])
	let recruitList = ref([])
	let quesList = ref([])
	let activeItem = ref(0)
	let queryParams = reactive({
		pageNum: 1,
		pageSize: 6
	})
	onMounted(()=>{
		getList()
		getQues()
	})
	watch(activeItem, (newVal, oldValue) => {
		getContent(newVal)
	})
	const getQues = () => {
		getPartDetail({
			moduleId: proxy.partten.moduleQuesList,
		}).then((res)=>{
			quesList.value = res.rows;
		})
	}
	const getList = () => {//校园招聘7
		getPartList(proxy.partten.partRecruitSchool).then((res)=>{
			tabList.value = res.data;
			if(res.data.length > 0){
				activeItem.value = res.data[0].id
			}
		})
	}
	const getContent = (val) => {
		queryParams = Object.assign(queryParams, { ifPage: 1, moduleId:val });
		getRecruitList(queryParams).then((res)=>{
			recruitList.value = res.data;
		})
	}
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
.questions{
	padding: 40px 0 0;box-sizing: border-box;
	.quesItem{
		line-height: 24px;padding-bottom: 40px;font-size: 14px;
		h4{
			color: #575d6a;
		}
		p{
			color: rgba(0,0,0,0.4);
		}
	}
}

</style>
