const MPKey = 'GDCBZ-6YLLU-PMVVH-4LOFA-TQGAE-RYBPQ';
export function MP(){
	  return new Promise(function(resolve, reject) {
		  // window.onload = function(qq) {
			 //  resolve(qq) //注意这里
		  // }
		  if(typeof window.TMap !=="undefined"){
			  resolve(window.TMap)
			  return true
		  }
		  window.onMapCallback = function() {
				resolve(window.TMap);
		  };
		  var script = document.createElement("script");
		  script.type = "text/javascript";
		  script.src = "https://map.qq.com/api/gljs?v=2.exp&key=" + MPKey + '&callback=onMapCallback';
		  script.onerror = reject;
		  document.head.appendChild(script);
		  
	  })
}