import cache from '@/plugins/cache'
import { defineStore } from 'pinia'
// import { login, logout, getInfo, register } from '@/api/login'
// import { getToken, setToken, removeToken } from '@/utils/auth'

const useUserStore = defineStore(
  'user',
  {
    state: () => ({
      // token: getToken(),
      name: '',
      avatar: '',
	  userInfo: cache.session.getJSON('zlUser-Info') ? cache.session.getJSON('zlUser-Info') : {},
    }),
    actions: {
		// 注册
		register(userInfo) {
		  return new Promise((resolve, reject) => {
		    // register(userInfo).then(res => {
		    //   setToken(res.data.api_token)
		    //   this.token = res.data.api_token
		    //   resolve(res)
		    // }).catch(error => {
		    //   reject(error)
		    // })
		  })
		},
      // 登录
      login(userInfo) {
        return new Promise((resolve, reject) => {
          // login(userInfo).then(res => {
          //   setToken(res.data.api_token)
          //   this.token = res.data.api_token
          //   resolve(res)
          // }).catch(error => {
          //   reject(error)
          // })
        })
      },
      // 获取用户信息
      getInfo() {
        return new Promise((resolve, reject) => {
     //      getInfo().then(res => {
			  // this.avatar = res.data.avatar_url;
			  // this.name = res.data.real_name;
			  // cache.session.setJSON('zlUser-Info', res.data);
			  // resolve(res)
     //      }).catch(error => {
     //        reject(error)
     //      })
        })
      },
      // 退出系统
      logOut() {
        return new Promise((resolve, reject) => {
          // logout().then((res) => {
          //   this.token = ''
          //   removeToken()
          //   resolve(res)
          // }).catch(error => {
          //   reject(error)
          // })
        })
      }
    }
  })
  
export default useUserStore
