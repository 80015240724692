<template>
<div class="subBannerBox" :class="{'m':isMobile()}">
	<swiper
	  :spaceBetween="30" 
	  :centeredSlides="true" 
	  :autoplay='{ "delay": 2500, "disableOnInteraction": false }' 
	  :pagination='{ "clickable": true }' 
	  :navigation="true" 
		:style="{
		  '--swiper-navigation-color': '#ff5a00',
		  '--swiper-pagination-color': '#ff5a00'
		}"
	  class="mySwiper"
	  >
	  <swiper-slide v-for="(item,index) in bannerList" :key="index">
	  	<div class="subbanner">
	  		<img :src="item.resourceLink">
	  	</div>
	  </swiper-slide>
	  <!-- <swiper-slide><div class="subbanner"><img src="@/assets/images/subbanner01.jpg"></div></swiper-slide>
	  <swiper-slide><div class="subbanner"><img src="@/assets/images/subbanner02.jpg"></div></swiper-slide>
	  <swiper-slide><div class="subbanner"><img src="@/assets/images/subbanner03.jpg"></div></swiper-slide> -->
	</swiper>
</div>
</template>

<script setup>
	import { isMobile } from '@/utils/common.js'
	import { Swiper, SwiperSlide } from 'swiper/vue';
	
	import SwiperCore, { Autoplay,Pagination,Navigation } from 'swiper/core';
	SwiperCore.use([Autoplay,Pagination,Navigation]);
	
	import { getPartDetail } from '@/api/index'
	import { ref, onMounted } from 'vue'
	
	const bannerList = ref([])
	//19综合轮播图
	getPartDetail({ ifPage: 0, moduleId:19 }).then((res)=>{
		bannerList.value = res.rows;
	})
</script>

<style lang="less" scoped>
	.subBannerBox{
		width: 100%;height: 340px;
		.mySwiper{
		    width: 100%;height: 340px;
			.subbanner{
				position: relative;width: 100%;height: 100%;overflow: hidden;
				img{
					height: 100%;position: absolute;
					top: 0;left: 50%;transform: translate(-50%,0);
				}
			}
		}
		&.m{
			height: 200px;
			.mySwiper{
				height: 200px;
			}
		}
		/deep/ .swiper-pagination-bullets{
			bottom: 0;padding: 12px 0 8px;background: rgba(0,0,0,0.4);
			.swiper-pagination-bullet{
				border-radius: 2px;width: 14px;height: 14px;
			}
		}
	}
</style>
