import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout'
import { pcRoutes } from './pc.js'
import { mRoutes } from './m.js'
import { isMobile } from '@/utils/common.js'

const currentRoutes = isMobile() ? mRoutes : pcRoutes;
const routes = [
  {
    path: '',
    component: Layout,
    redirect: '/index',
    children: currentRoutes
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import('@/views/error/404.vue'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error/401.vue'),
    hidden: true
  },
]

const router = createRouter({
	mode:'history',
	routes,
	history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
